// import images from all-images/blog-img directory
import img01 from "../all-images/blog-img/E-lyte Electrolyte analyzer.jpg";
import img02 from "../all-images/blog-img/GS5 5-Part Auto Hematology Analyzer.png";
import img03 from "../all-images/blog-img/COAGULA.png";
import img04 from "../all-images/blog-img/GS3 3-Part Auto Hematology Analyzer.jpg";
import img05 from "../all-images/blog-img/Mindray DP-1100plus Ultasound.jpg";
import img06 from "../all-images/blog-img/Mindray Dp-20 Ultrasound.jpg";
import img07 from "../all-images/blog-img/Mindray Z-5 Ultrasound.jpg";
import img08 from "../all-images/blog-img/Mindray DP-10 Ultrasound.jpg";
import img09 from "../all-images/blog-img/A2E Combined transparent monitor.png";
import img10 from "../all-images/blog-img/A5 Modular monitor.png";
import img11 from "../all-images/blog-img/A3 Modular Patient Monitor.png";
import img12 from "../all-images/blog-img/A8 Modular Patient Monitor.jpg";
import img13 from "../all-images/blog-img/M12 Patient Monitor.jpg";
import img14 from "../all-images/blog-img/M10 Patient Monitor.jpg";
import img15 from "../all-images/blog-img/M9500 Multi-Parameter Patient Monitor.png";
import img16 from "../all-images/blog-img/M8000A Patient Monitor.png";
import img17 from "../all-images/blog-img/M800 Handheld Monitor.png";
import img18 from "../all-images/blog-img/Q5 Modular Patient Monitor.png";
import img19 from "../all-images/blog-img/Q7 Modular Patient Monitor.png";
import img20 from "../all-images/blog-img/Q3 Neonatal Monitor.png";


const blogData = [
  {
    id: 1,
    title: "E-lyte Electrolyte analyzer",
    imgUrl: img01,
    description: [
      "Excellent precision and reliability",
      "Long life, high performance, maintenance-free electrodes",
      "Automatic sampling, priming, rinsing, and calibration",
      "Stores 200 patient results",
      "Touch-screen and Large LCD with backlight",
      "Automatic or On-demand calibration capability"
    ],

    quote: "E-lyte Electrolyte analyzer",
  },

  {
    id: 2,
    title:
      "GS5 5-Part Auto Hematology Analyzer",
    imgUrl: img02,
    description : [
      "Uses Tri-angle Laser scatter + Flow cytometry technology",
      "Venous whole blood, capillary whole blood and Pre-diluted modes",
      "Powerful capability of flagging abnormal cells",
      "10.4 inch large TFT touch screen with user friendly software",
      "Large storage capacity, up to 50,000 samples"
  ],
    quote:
      "GS5 5-Part Auto Hematology Analyzer",
  },

  {
    id: 3,
    title: "COAGULA TWO CHANNELS COAGULOMETER",
    imgUrl: img03,
    description:[
      "Is a high reliability coagulometer, able to perform the main coagulation tests.",
      "PT: result in Seconds, RATIO, QUICK%, INR.",
      "TT: result in Seconds and RATIO.",
      "FACTOR: result in Seconds, RATIO and ACTIVITY%."
  ],
    quote: "COAGULA TWO CHANNELS COAGULOMETER",
  },
  {
    id: 4,
    title: "GS3 3-Part Auto Hematology Analyzer",
    imgUrl: img04,
    description:[
     	"Throughput:  60 samples/ hour.",
      "Detection channel:  Dual-channel for counting.",
      	"Detection principle: Impedance method (WBC/RBC/PLT) and cyanide free colorimetric method (HGB).",
      	"Detection items:  The detection items  21 parameters"

  ],
    quote: "COAGULA TWO CHANNELS COAGULOMETER",
  },
  {
    id: 5,
    title: "Mindray DP-1100plus Ultasound",
    imgUrl: img05,
    description:[
      " Tissue Specific Imaging optimizes the image quality based on the properties of the tissue being scanned.", 
      "8-segment TGC assure delicate image adjustments.",
      "Measurement & calculation software packages",
      "10’’ non-interlaced monitor",
      "Backlit control panel, suitable for dark room operation"
  ],
    quote: "Mindray DP-1100plus Ultasound",
  },
  {
    id: 6,
    title: "Mindray Dp-20 Ultrasound",
    imgUrl: img06,
    description:[
      "Utilizing second harmonics generated from tissue boundary layers,",
      "THI significantly enhances contrast resolution and improves image ",
      "quality especially for technically difficult subjects.",
      "Tissue Specific Imaging optimizes the image quality based on the properties of the tissue being scanned.",
      "High resolution 12.1” LED with tilt functionality",
      "User-friendly keyboard and controls"
  ],
    quote: "Mindray Dp-20 Ultrasound",
  },
  {
    id: 7,
    title: "Mindray Z-5 Ultrasound",
    imgUrl: img07,
    description:[
      "Compact and streamlined design weighing 7.7kg",
      "15” LCD monitor with 30 degree tilt function",
      "Dual universal transducer connectors",
      "Two level backlit keyboard for varying requirements:",
      "1.5h uninterrupted scanning with rechargeable battery"
  ],
    quote: "Mindray Z-5 Ultrasound",
  },{
    id: 8,
    title: "Mindray DP-10 Ultrasound",
    imgUrl: img08,
    description:[
      "12.1 inch high definition LED",
      "Full Screen design for easy cleaning",
      "30 degree tilting angle adjustable monitor",
      "Maximum 2 universal transducer connectors",
      "User friendly control panel with backlit",
      "Light and compact design for extreme portability"
      
  ],
    quote: "Mindray DP-10 Ultrasound",
  },{
    id: 9,
    title: "A2E Combined transparent monitor",
    imgUrl: img09,
    description:[
      "3.5 inch HD color LCD display",
      "Multi-channel waveform display ",
      "Full-screen touch",
      "Multi-parameter monitoring module EMS is the carrier of the monitoring data",
      "•	Fan less design for quiet and clean environment "
  ],
    quote: "A2E Combined transparent monitor",
  },{
    id: 10,
    title: "A5 Modular monitor",
    imgUrl: img10,
    description:[
      "12 inch color LCD screen, multi-channel       waveform display",
      "Fan less design, dust-free, low power consumption, improve equipment life",
      "Built-in removable rechargeable lithium battery,",
      "External SD memory card, the patient history data for effective storage"
  ],
    quote: "A5 Modular monitor",
  },{
    id: 11,
    title: "A3 Modular Patient Monitor",
    imgUrl: img11,
    description:[
      "8 inch color LCD screen, multi-channel waveform display",
      "The module is complete, can be any combination: support EMS",
      "module box (optional 3/5/12 guide ECG, RESP, NIBP, TEMP, IBP, SpO2), CO2, AG, ICG and other modular box",
      "Configurable recorder, record monitoring data and waveforms",
      "With a lithium battery, to ensure continuous monitoring"
  ],
    quote: "A3 Modular Patient Monitor",
  },{
    id: 12,
    title: "A8 Modular Patient Monitor",
    imgUrl: img12,
    description:[
      "17 inch large screen anti-glare color LCD display, full-screen touch button operation",
      "Can be an independent large-screen display, fast call urgent need to pay attention to life information",
      "Built-in removable rechargeable lithium battery, lightweight, more environmentally friendly energy",
      "Unique luminous buttons for easy night operation"
  ],
    quote: "A8 Modular Patient Monitor",
  },{
    id: 13,
    title: "M12 Patient Monitor",
    imgUrl: img13,
    description:[
      "12’ high definition screen, resolution 1280×800",
      "Large capacity for data storage enables comprehensive review of patient’s data,",
      "Extra large Storage box can be easily put into the whole set of accessories",
      "The innovative algorithm can monitor the real time Respiration Rate from Pleth (RRP)"
  ],
    quote: "M12 Patient Monitor",
  },{
    id: 14,
    title: "M10 Patient Monitor",
    imgUrl: img14,
    description:[
      " 10’ high definition screen, resolution 1024×600",
      " 3kg weight, portable & convenient to move and vehicle carried.",
      " 8-hour continuous runtime with one Lithium-ion battery",
      " Various screen layouts for different clinical needs, such as 7-lead, large font, other bed, etc."
  ],
    quote: "M10 Patient Monitor",
  },{
    id: 15,
    title: "M9500 Multi-Parameter Patient Monitor",
    imgUrl: img15,
    description:[
      "Colorful and clear 15 ＂TFT LCD",
      "Applicable ECG cable of 3/5 leads, automatic recognition", 
      "72h trend data/trend maps storage and reviewing",
      "Thermal Recorder with real-time recording and alarm-trigger printing function is available as an option",
      "Built-in detachable rechargeable lithium battery"

  ],
    quote: "M9500 Multi-Parameter Patient Monitor",
  },{
    id: 16,
    title: "M8000A Patient Monitor",
    imgUrl: img16,
    description:[
      " 10.4 high-definition color TFT display,",
      " Button for the silicone backlight button, easy to use at night and dark environment",
      " The channel to be printed waveform can be arbitrarily selected Built-in pluggable rechargeable Lithium Battery:",
      " Uninterrupted monitoring Standard parameters: ECG, SpO2, PR, HR, Resp, NIBP, Temp.",
      " Option parameter:  2-IBP,  Nellcor SpO2, EtCO2(Side Stream, Main Stream)"
  ],
    quote: "M8000A Patient Monitor",
  },{
    id: 17,
    title: "M800 Handheld Monitor",
    imgUrl: img17,
    description:[
      "Compact, light for carrying and handling, Providing patient ECG, SpO2 and PR monitoring.",
      "Flexible visual angle, high resolution 2.4 inch color OLED display and power saving",
      "Automatically distinguishing lithium ion battery from alkaline battery and charging lithium ion battery."
  ],
    quote: "M800 Handheld Monitor",
  },{
    id: 18,
    title: "Q5 Modular Patient Monitor",
    imgUrl: img18,
    description:[
      "Portable operation, multi-pronged approach",
      "Light and portable, plug-in flexible",
      "Two expansion slots for easy upgrades",
      "Multiple alarm tips function",
      "Any angle can see the alarm"
    ],
    quote: "Q5 Modular Patient Monitor",
  },{
    id: 19,
    title: "Q7 Modular Patient Monitor",
    imgUrl: img19,
    description:[
      "15-inch high-definition color TFT display",
      "Multi-channel waveform display",
      "Fast button backlit design for easy night operation",
      "Ergonomic knob operation, classic and flexible",
      "Fan less design, low power consumption"
  ],
    quote: "Q7 Modular Patient Monitor",
  },{
    id: 20,
    title: "Q3 Neonatal Monitor",
    imgUrl: img20,
    description:[
      "built-in pluggable lithium battery, battery power supply time of 5 hours or more, and has a car power interface",
      "FDA, CE Approval",  
      "Optional built-in thermal three-channel recorder, the channel to be printed waveform can be arbitrarily selected"
  ],
    quote: "Q3 Neonatal Monitor",
  },
];

export default blogData;
