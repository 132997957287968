import React from "react";

import CommonSection from "../components/UI/CommonSection";
import Helmet from "../components/Helmet/Helmet";
import AboutSection from "../components/UI/AboutSection";
import { Container, Row, Col } from "reactstrap";
import BecomeDriverSection from "../components/UI/BecomeDriverSection";

import driveImg from "../assets/all-images/card1.png";
import cardImage from "../assets/all-images/card1.png";
import OurMembers from "../components/UI/OurMembers";
import "../styles/about.css";

const About = () => {
  return (
    <Helmet title="About">
      <CommonSection title="About Us" />
      <AboutSection aboutClass="aboutPage" />

      <section className="about__page-section">
        <Container>
          <Row>
            <Col lg="6" md="6" sm="12">
              <div className="about__page-img">
                <img src={driveImg} alt="" className="w-100 rounded-3" />
              </div>
            </Col>

            <Col lg="6" md="6" sm="12">
              <div className="about__page-content">
                <h2 className="section__title">
                Our standards
                </h2>

                <p className="section__description">
                Yousira Pharma is regulated by the Ethiopian Food and Drug Authority(EFDA). All our processes have been designed with Good Distribution Practice (GDP) embedded. 
                <br/>
                We have established a unique logistics system to ensure a stable supply of a wide range of pharmaceutical products.
                </p>
                <h2 className="section__title">
                Our Mission 
                </h2>
                <p className="section__description">
                The mission of Yousira PHARMA PLC is to be one of the leading distributors of pharmaceuticals and medical equipment in the pharmaceutical sector in Ethiopia by continuously improving the quality of our services, through uninterrupted supply, reasonable pricing and generally by satisfying our esteemed customers’ needs.
                </p>

                <div className=" d-flex align-items-center gap-3 mt-4">
                  <span className="fs-4">
                    <i class="ri-phone-line"></i>
                  </span>

                  <div>
                    <h6 className="section__subtitle">Need Any Help?</h6>
                    <h4>+251911251241/42/43</h4>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <BecomeDriverSection />

      <section>
        {/* <Container>
          <Row>
            <Col lg="12" className="mb-5 text-center">
              <h6 className="section__subtitle">Experts</h6>
              <h2 className="section__title">Our Staffs</h2>
            </Col>
            <OurMembers />
          </Row>
        </Container> */}
      </section>
    </Helmet>
  );
};

export default About;
